import { useForm } from 'react-hook-form'

import {
  Navigation,
  OptIn,
  Input,
  If
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import './proposal-name.scss'
import { useName } from './useName/index'

const Name = ({ pageContext }) => {
  const {
    leadData: { name, cpf }
  } = useLead()

  const initialValues = {
    name: name ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })
  const hasDocument = !!cpf

  const { onSubmit, isButtonLoading, isProd } = useName({
    hasDocument
  })

  return (
    <ProposalLayout pageContext={pageContext}>
      <>
        <h2 className="proposal-nome__subtitle">Boas-vindas ao Itaú!</h2>
        <h1 className="proposal-nome__title">{'Como você se chama?'}</h1>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <If
            condition={isProd}
            renderIf={
              <Input.Name
                label="Nome completo"
                data-cy="cy-input-name"
                id="name"
                name="name"
                autoComplete="on"
                control={control}
              />
            }
            renderElse={
              <Input.Text
                label="Nome completo"
                data-cy="cy-input-name"
                id="name"
                name="name"
                autoComplete="on"
                control={control}
              />
            }
          />

          <OptIn />
          <Navigation
            submit
            nextStep
            isNextStepButtonLoading={isButtonLoading}
            isNextButtonDisabled={!isValid}
            nextButtonId={'basic-data-next-btn'}
            dataCy="cy-btn-next"
            nextButtonText="Continuar simulação"
          />
        </form>
      </>
    </ProposalLayout>
  )
}

export default Name
