import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getFullName = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'full-name',
    method: 'get'
  })

  return data
}
const putFullName = async ({ name }) => {
  const payload = { full_name: name }

  const { data } = await defaultLeadsApiCall({
    endpoint: 'full-name',
    method: 'put',
    body: payload
  })

  return data
}

export default { getFullName, putFullName }
